body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
}

button {
  border: none;
  outline: none;
  width: 100%;
  padding: 15px 0;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  background: #603bbb;
  cursor: pointer;
}

.login {
  width: 100%;
  min-height: 100vh;
  padding: 0 20px;
  background: #e9e9e9;
  display: flex;
}

.login .loginContainer {
  padding: 60px;
  margin: auto;
  width: 100%;
  max-width: 520px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: radial-gradient(
    ellipse at left bottom,
    rgba(22, 24, 47, 1) 0%,
    rgba(38, 20, 72, 0.9) 59%,
    rgba(17, 27, 75, 0.9) 100%
  );
  box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
}

.login .loginContainer label {
  color: white;
  margin: 14px 0;
  display: block;
  font-size: 22px;
  line-height: 1;
}

.login .loginContainer input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  letter-spacing: 1px;
}

.login .loginContainer .btnContainer {
  width: 100%;
  padding: 24px 0;
}

.login .loginContainer .btnContainer p {
  margin: 14px 0 0 0;
  text-align: right;
  color: #fff;
}

.login .loginContainer .btnContainer p span {
  color: yellow;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 5px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

.login .loginContainer .btnContainer p span:hover {
  color: red;
}

.login .loginContainer .errorMsg {
  color: red;
  font-size: 16px;
}

.hero {
  width: 100%;
  min-height: 100vh;
  background: radial-gradient(
    ellipse at left bottom,
    rgba(22, 24, 47, 1) 0%,
    rgba(38, 20, 72, 0.9) 59%,
    rgba(17, 27, 75, 0.9) 100%
  );
}

.hero nav {
  background: #603bbb;
  width: 100%;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero nav h2 {
  color: #fff;
}

.hero nav button {
  width: 86px;
  padding: 8px 0;
  background: #3a2b58;
}

.wrapper {
  margin:2rem;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns:1fr;
  justify-content: center;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.card{
  flex: 0 0 250px;
  margin: 10px;
  border: 1px solid #ccc;
  background-color: aqua;
  box-shadow: 2px 2px 6px 0px  rgba(0,0,0,0.3);
}

.card1{
  flex: 0 0 250px;
  margin: 10px;
  border: 1px solid #ccc;
  background-color: green;
  box-shadow: 2px 2px 6px 0px  rgba(0,0,0,0.3);
}

.card .card_title{
  padding-top: 5px;
  padding-left: 50px;
  padding-bottom: 5px;
  font-size:large;
}

.card .card_value{
  padding-top: 5px;
  padding-left: 75px;
  padding-bottom: 5px;
}

.chartArea {
  height: 800px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  
}

.calenderPlace {
  height: 50px;
  width: 100%;
  background-color: #3a2b58;
  align-items: center;
  justify-content: center;
  display: flex;
}





